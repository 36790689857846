import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Footer from "../homepage/components/Footer";
import "../App.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
// import imagedata from "./imagedata";
import imagedata from "../homepage/imageslider/imagedata";
import { NavLink,useNavigate } from "react-router-dom";
import useSmallScreen from "../homepage/components/customcomponent/useSmallScreen";
import Navbar from "../homepage/navbar/Navbar";
const Terms = () => {
  const navigate = useNavigate()
  const isSmallScreen = useSmallScreen();
  const [isSticky, setIsSticky] = useState(false);
  const [showNavbars, setShowNavBars] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const showNavbar = () => {
    setShowNavBars((data) => !data);
  };

  return (
    <div>
     <Navbar />
      <div className="container">
        <div className="py-[48px]">
          <h1 className="font-bold py-4 font-manrope text-xl text-black">
            PlayMaster11 Terms and Conditions
          </h1>

          <ul className="list-disc">
            <li>
              This document is published in accordance with the provisions of
              Rule 3(1) of the Information Technology (Intermediaries Guidelines
              and Digital Media Ethics Code) Rules, 2021 that require publishing
              the rules and regulations, privacy policy and user agreement for
              access or usage of our website ‘www.PlayMaster11.com’ (hereinafter
              referred to as the "Website", “Mobile Application”, or
              “Platform”). PlayMaster11 Fantasy Sports Private Limited is
              incorporated under the Companies Act, 2013 having its registered
              office at Fno -203,tower-a4,sec-107, Signature Global Solera,
              Industrial Estate (gurgaon), Gurgaon, Industrial Estate, Haryana,
              India, 122007 (hereinafter, referred to as "we", "us", "our" and
              “PlayMaster11”).
            </li>
            <li>
              This document is an electronic record in terms of the Information
              Technology Act, 2000 and rules made there under as applicable and
              the amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </li>
            <li>
              For the purpose of these Terms and Conditions (defined
              hereinafter), "you", "your" or “User” refers to any natural or
              legal person (whether a registered user or otherwise) who accesses
              the Website, its contents and uses the services offered by
              PlayMaster11 on or through the Website.
            </li>
            <li>
              Your use of our Website and its related sites, services and tools
              is governed by these Terms and Conditions contained in this
              agreement that applies to the Website (“User Terms”). By using the
              Website, you have impliedly agreed to and accepted these User
              Terms. If you do not agree or are not willing to be bound by the
              terms and conditions of these User Terms (and the rules and
              policies displayed on the Website), please do not click on the
              REGISTER/LOGIN, SUBSCRIBE NOW or any other related buttons and do
              not seek to obtain access to or otherwise use the Website. As long
              as you comply with the provisions of these User Terms, we grant
              you a personal, non-exclusive, non-transferable, limited privilege
              to enter and use the Website.
            </li>
            <li>
              PlayMaster11 is not affiliated with any private or government
              sports leagues or tournaments unless explicitly stated.
              Additionally, PlayMaster11 does not have any official association
              with any sports teams or individuals.
            </li>
            <li>
              The term "PlayMaster11" used in these Terms and Conditions, refers
              to both the PlayMaster11 Website and Mobile Application on Android
              Play Store and iOS App Store.
            </li>
            <h1 className="font-bold py-4 font-manrope text-xl text-black">
              1. INTELLECTUAL PROPERTY
            </h1>
            <li>
              You may download or copy Content only to the extent such download
              is expressly permitted in writing on the Website. No right, title
              or interest in any downloaded materials or software is transferred
              to you as a result of any such downloading or copying. You may not
              reproduce (except as noted above), publish, transmit, distribute,
              display, modify, create derivative works from, sell or participate
              in any sale of or exploit in any way, in whole or in part, any of
              the Content, the Website or any related software.
            </li>
            <li>
              Nothing on the Website will be interpreted as granting any license
              or right to use any image, trademark, logo or service mark on the
              Website. Anything transmitted to the Website by you becomes
              PlayMaster11’s property and may be used by us for any lawful
              purpose. PlayMaster11 reserves all rights with respect to
              copyright and trademark ownership of all material on the Website,
              and will enforce such rights to the full extent of the law.
            </li>
            <li>
              Unless otherwise noted, all Contents are copyrights, trademarks
              and/or other intellectual property owned, controlled or licensed
              by PlayMaster11 or by third parties who have licensed their
              materials to PlayMaster11 and are protected by Indian and
              international copyright laws. The compilation of all Contents on
              the Website is the exclusive property of PlayMaster11 and is also
              protected by Indian and international copyright laws.
            </li>
            <li>
              The Website, including all Information, is owned by or licensed to
              PlayMaster11 and is subject to and protected by various
              intellectual property rights, including but not limited to the
              copyright, trade secrets, trademarks, service marks, brand names
              and other proprietary rights whether under contract, statute or
              any similar provisions ( “IP Rights” ). All IP Rights are and will
              remain the exclusive property of PlayMaster11, its respective
              third-party licensors or third parties to whom it is attributed
              and in using the Website, individual Users will not obtain any
              rights, title or other interest in or to any information on the
              Website or related IP Rights.
            </li>
            <li>
              Subject to the above, individual Users are entitled to view the
              Information on the Website and to copy and print such information
              for personal use. Individual Users are not permitted to sell or
              distribute or otherwise deal with the Information on the Website
              or any deviations of such information without the prior written
              consent of PlayMaster11. The Website uses different web analytics
              services and is compliant with its Terms and Conditions.
            </li>
            <li>
              By accepting the T&Cs, the User grants PlayMaster11 and its
              affiliates, partners, licensors, and associates a worldwide,
              irrevocable, royalty-free, non-exclusive, sublicensable licence to
              use, reproduce, create derivative works, distribute, publicly
              perform, publicly display, transfer, transmit and/or publish their
              User's Content for the following purposes:
            </li>
            <li>Displaying User's Content on PlayMaster11;</li>
            <li>
              Distributing User's Content to other users, electronically or
              through other media;
            </li>
            <li>
              Storing User's Content in a remote database accessible by end
              users, for a charge; and
            </li>
            <li>
              This licence applies to the distribution and storage of the User's
              Content in any form, medium or technology.
            </li>
            <li>
              Permission to modify, publish, transmit, sell, reproduce, create
              derivative works or publicly display any content on
              www.PlayMaster11.com can be requested at contact@playmaster11.com.
            </li>
            <li>
              The User is solely responsible for any material it uploads, posts,
              emails, transmits or make available on PlayMaster11 ( "User’s
              Content" ). The User ensures that it holds the intellectual
              property rights in their User's Content and that it does not
              infringe any third-party rights. User also agrees not to use or
              display any names, logos, marks, labels, trademarks, copyrights or
              intellectual property rights of any third party on PlayMaster11.
              User accepts and agrees that PlayMaster11 and its directors,
              employees, affiliates, and assigns will not be liable for any
              costs, damages, loss or harm, including litigation costs and
              counsel fees, arising from any claims by a third party resulting
              from the display or use of any names, logos, marks, labels,
              trademarks, copyrights or intellectual property rights on
              PlayMaster11 through the User's omissions.
            </li>
            <li>
              The User consents to receive communications, such as
              announcements, administrative messages and advertisements, from
              PlayMaster11 or any of its partners, licensors or associates.
            </li>
          </ul>
          <h1 className="font-bold py-4 font-manrope text-xl text-black">
            2. USAGE OF WEBSITE AND MOBILE APPLICATION
          </h1>
          <ul className="list-disc">
            <li>
              Users using the services offered by www.PlayMaster11.com or the
              PlayMaster11 App, including Features, by participating in various
              contests and games organised on the platform (“Contests”) are
              bound to follow these Terms and Conditions, and all other rules
              and regulations defined by PlayMaster11.
            </li>
            <li>
              PlayMaster11 reserves the right to modify or change these Terms
              and Conditions, rules and regulations at any time by posting them
              on www.myteaam11.com. By using PlayMaster11 services, Users agree
              to time-to-time amendments that may be made. Users will be
              informed of any amendments via email to their registered address
              or by posting notifications in their accounts. If a User disagrees
              or does not accept the amendments, they can indicate their
              non-acceptance within the defined time frame. If a user fails to
              do so, they will be deemed to have accepted the changes. In case
              of non-acceptance, PlayMaster11 reserves the right to restrict the
              User from using its services and features.
            </li>
            <li>
              Additional rules and regulations may apply to the various services
              offered as part of the features. If there are additional
              conditions that conflict with these Terms and Conditions, the
              additional conditions will take precedence.
            </li>
            <li>
              PlayMaster11 offers several interactive functionalities on the App
              to enhance the user experience. These functionalities are referred
              to as “features” in all the sections of these Terms and
              Conditions.
            </li>
            <li>PlayMaster11 may, at its discretion:</li>
            <li>
              Restrict, suspend or terminate a User's access to all or part of
              www.PlayMaster11.com or features of PlayMaster11.
            </li>
            <li>Change, suspend or discontinue all or part of the features.</li>
            <li>
              Deactivate or delete a User's account and related information and
              files.
            </li>
            <li>
              Reject, move or remove any material submitted by a User (v) Move
              or remove any content available on www.PlayMaster11.com and
              PlayMaster11 application (vi) Establish general practices and
              limits for the use of www.PlayMaster11.com and PlayMaster11
              application
            </li>
            <li>
              Revise or add to the roster of players for selection in a Contest
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
