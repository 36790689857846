import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Footer from "../homepage/components/Footer";
import "../App.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
// import imagedata from "./imagedata";
import imagedata from "../homepage/imageslider/imagedata";
import { NavLink, useLocation } from "react-router-dom";
import useSmallScreen from "../homepage/components/customcomponent/useSmallScreen";
import Navbar from "../homepage/navbar/Navbar";
const Contact = () => {
   const location = useLocation();
  
  const isSmallScreen = useSmallScreen();
  const [isSticky, setIsSticky] = useState(false);
  const [showNavbars, setShowNavBars] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
 

  useEffect(() => {
    if (location.state?.scrollTo) {
      const element = document.getElementById(location.state.scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div>
      <Navbar />

      <div className="container" id="contactSection">
        <div className="py-[48px]">
          <h1 className="font-bold py-4 font-manrope text-xl text-black">
            Contact Us
          </h1>
          <p className="py-2">Last updated on 17-05-2024 19:06:23</p>
          <p className="py-2">
            You may contact us using the information below:
          </p>
          <p className="py-2">
            Merchant Legal entity name: SHIVACHA TECHNOLOGIES PRIVATE LIMITED
          </p>
          <p className="py-2">
            Registered Address: Fno -203,tower-a4,sec-107, Signature Global
            Solera, Industrial Estate, Gurgaon, Haryana,PIN: 122007
          </p>
          <p className="py-2">
            Operational Address: Fno -203,tower-a4,sec-107, Signature Global
            Solera, Industrial Estate, Gurgaon, Haryana, PIN: 122007
          </p>
          <p className="py-2">Telephone No: 9910164515</p>

          <p className="py-2">E-Mail ID: support@playmaster.com</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
