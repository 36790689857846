import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Footer from "../homepage/components/Footer";
import "../App.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/swiper-bundle.css";
// import imagedata from "./imagedata";
import imagedata from "../homepage/imageslider/imagedata";
import { NavLink,useNavigate } from "react-router-dom";
import useSmallScreen from "../homepage/components/customcomponent/useSmallScreen";
import Navbar from "../homepage/navbar/Navbar";
const Refund = () => {
  const navigate = useNavigate()
  const isSmallScreen = useSmallScreen();
  const [isSticky, setIsSticky] = useState(false);
  const [showNavbars, setShowNavBars] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const showNavbar = () => {
    setShowNavBars((data) => !data);
  };

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="py-[48px]">
          <h1 className="font-bold py-4 font-manrope text-xl text-black">
            Refund Policy
          </h1>
          <p>
            We refund your money within 24 hours when a contest is cancelled.
            This happens only if the match is called off or the contest does not
            gather the required number of contestants.
          </p>
          <p className="py-2">
            A match can be called off without a ball bowled or sometimes even
            after the start of play due to rain, dangerous pitch, among other
            reasons.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
